import React, {useState, useCallback, useEffect} from 'react';
import {useNavigate, useParams} from 'react-router-dom';
import DashboardHeader from '../../layout/DashboardHeader';

import '../styles.css';
import 'bootstrap/dist/css/bootstrap.css';

import {db} from '../../../firebase';
import {updateDoc, doc} from 'firebase/firestore';
import GetSingleDoc from '../../../firebase/functions';

import Alert from 'react-bootstrap/Alert';
//import Button from 'react-bootstrap/Button';



function EditBuilder ({ btnText, onClick }) {
     // Get ID from URL
     const params = useParams();
     let getid=params.id;

    //get current record in collection from FireStore
    let builder=null;
    builder=GetSingleDoc('builder', getid);
    useEffect(()=>{
        //let qtyAct;
        
        if(builder!==null){
            builder=JSON.parse(builder)
            //alert(actividad.comments)
            setConstructura(builder.company)
            setEmail(builder.email)
            setConstructor(builder.name)
            setPhone(builder.phone)
        }
    },[builder])
   

    const navigate = useNavigate();
    const handleOnClick = useCallback(() => navigate('/viewbuilders', {replace: true}), [navigate]);

    const [showA, setShowA] = useState(false);
    const [docID, setdocID] = useState(false);


    const [constructura, setConstructura] = useState('')
    const [email, setEmail] = useState('')
    const [constructor, setConstructor] = useState('')
    const [phone, setPhone] = useState('')



    const handleSubmit = async (e) => {
        e.preventDefault()
        //console.log(newBook)
        //const ref=collection(db, 'builder')
        const ref = doc(db, "builder", getid);
    
       /*let result= await addDoc(ref, {
          active: true,
          company: constructura,
          createdDate:new Date(),
          createdUserID: '',
          email: email,
          name:constructor,
          phone:phone
        })*/

        let result= await updateDoc(ref, {
            company: constructura,
            email: email,
            name:constructor,
            phone:phone
        })

        
        setShowA(true);
          

        //console.log(result.id);
    
       /* setConstructura('')
        setEmail('')
        setConstructor('')
        setPhone('')*/
        
    }

    return(
        <div className='dashboard-content'>
        <DashboardHeader
            btnText="Ver Constructores" onClick={handleOnClick}/>


        <div className='dashboard-content-container'>
            <div className='dashboard-content-header'>
                

                <div className="card card-body">
                    {showA && (
                    <Alert variant="success" onClose={() => setShowA(false)} dismissible>
                    <Alert.Heading>Constructora cmabiada! Feliciades!</Alert.Heading>
                        <p>
                        Builder con ID:<strong> {getid} </strong> cambiado exitosamente
                        </p>
                    </Alert>
                    )
                    }

                <h2>Cambiar Constructora</h2>
                <div className='dashboard-content-search'>
                   
                </div>

                             <div className="card-header bg-danger" style={{marginBottom:'5px'}}>
                                <h4 className="mb-0 text-white">Cambiar constructor</h4>
                            </div>
                            
                            <form className="form-horizontal" onSubmit={handleSubmit}>
								<div className="mb-3">
                                    <label>Nombre de Constructora</label>
                                    <input className="form-control" type="text" placeholder="Empresa constructura" onChange={(e) => setConstructura(e.target.value)} value={constructura} required/>
                                </div>
								<div className="mb-3">
                                    <label>Correo Electronico</label>
                                    <input className="form-control" type="text" placeholder="Escriba aqui el correo" onChange={(e) => setEmail(e.target.value)} value={email} required />
                                </div>
                                <div className="mb-3">
                                    <label>Nombre del Representante</label>
                                    <input className="form-control" type="text" placeholder="Constructor or representante" onChange={(e) => setConstructor(e.target.value)} value={constructor} required />
                                </div>
                                <div className="mb-3">
                                    <label>Telefono</label>
                                    <input className="form-control" type="text" placeholder="Telefono" onChange={(e) => setPhone(e.target.value)} value={phone} required />
                                </div>
                    
								<div className="form-actions">
                                        <div className="card-body border-top">
                                            <button type="submit" className="btn btn-primary rounded-pill px-4"> 
                                                <div className="d-flex align-items-center">
                                                    <i data-feather="save" className="feather-sm me-1 fill-icon"></i> Actualizar
                                                </div>
                                            </button>
                                            <button type="button" className="btn btn-danger rounded-pill px-4 ms-2 text-white">Cancelar</button>
                                        </div>
                                    </div>
                            </form>
                        </div>
            </div>
        </div>
    </div>
    )
}

export default EditBuilder;