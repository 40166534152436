//firebase imports
import { collection, onSnapshot, query, where } from "firebase/firestore";

import { useState, useEffect, useRef } from "react";
import { db } from '../firebase'


export const useCollection=(coleccion, _q)=>{
    const [documents, setDocuments]=useState(null)

    //set up query
    const q = useRef(_q).current

    useEffect(()=>{
        let ref=collection(db, coleccion)

        if(q){
            ref = query(ref, where(...q))
        }

        const unsub = onSnapshot(ref, (snapshot)=>{
            let results=[]
            snapshot.docs.forEach(doc=>{
                results.push({...doc.data(), id:doc.id})
            })
            setDocuments(results)
        })

        return () => unsub()
    }, [coleccion, q])

    return { documents }
}