import React, {useState, useCallback, useContext} from 'react';
import {useNavigate} from 'react-router-dom';
import DashboardHeader from '../../layout/DashboardHeader';

import '../styles.css';
import 'bootstrap/dist/css/bootstrap.css';

import {db} from '../../../firebase';
import {collection, addDoc, doc} from 'firebase/firestore';

import Alert from 'react-bootstrap/Alert';
//import Button from 'react-bootstrap/Button';
import AuthContext from '../../../store/auth-context';

import { useCollection } from '../../../hooks/useCollection';



function ReportForm ({ btnText, onClick }) {

    const [showA, setShowA] = useState(false);
    const [docID, setdocID] = useState(false);


    const [newLink, setNewLink] = useState('')
    const [newVid, setNewVid] = useState('')
    const [newType, setNewType] = useState('')
   // const [newNumber, setNewNumber] = useState('')
   
    const [newFecha, setNewFecha] = useState('')
    const [newMsg, setNewMsg] = useState('')
    //const [newImspectorid, setNewInspectorid] = useState('');
    //console.log('qty activities', notas);

    const authCtx = useContext(AuthContext);
    let user; let query;

    if (authCtx.userdetails){
        user=JSON.parse(authCtx.userdetails);
        //setNewInspectorid(user.id);
        if(user.type==='2'){
            query =['active', '==',  true];
        }else{
            query =['inspectorid', '==',  user.id];
            //setNewInspectorid(user.id);
        }
       
     }else{
         user='';
         query =['active', '==',  true];
     }

     const {documents: villas} = useCollection(
        'villas-lots',  query
    )
    
     //console.log('usuaurio: ', authCtx.userdetails)

     const {documents: notas} = useCollection(
        'activities',
        ['active', '==', true]
    )

    //console.log('inspector', newImspectorid);

    let qtyAct=1;

    if(notas!=null){
        notas.map(nota=>(qtyAct++))
    }

        //let qtyAct = Math.floor(Math.random()*10000000);


    const navigate = useNavigate();
    const handleOnClick = useCallback(() => navigate('/activities', {replace: true}), [navigate]);

    
    

    const handleSubmit = async (e) => {
        e.preventDefault()
        //setNewNumber(qtyAct)
        //alert(newVid)
       
        let villa=JSON.parse(newVid);
        //alert(villa.id)
        //console.log(newBook)
        const ref=collection(db, 'activities')
    
       let result= await addDoc(ref, {
          active: true,
          comments: newMsg,
          createdDate:new Date(),
          fecha:newFecha,
          inspectorid: user.id,
          inspdetails:user,
          number:qtyAct,
          type:newType,
          villaid:villa.id,
          villadetails:JSON.parse(newVid),
          link:newLink
        })

        if(result.id){
            setShowA(true);
            setdocID(result.id);
        }

        //console.log(result.id);
    
        setNewLink('')
        setNewVid('')
        setNewType('')
        //setNewNumber('')
        //setNewInspectorid('')
        setNewFecha('')
        setNewMsg('')
      }

    return(
        <div className='dashboard-content'>
        <DashboardHeader
            btnText="Ver reportes" onClick={handleOnClick}/>


        <div className='dashboard-content-container'>
            <div className='dashboard-content-header'>
                

                <div className="card card-body">
                    {showA && (
                    <Alert variant="success" onClose={() => setShowA(false)} dismissible>
                    <Alert.Heading>Reporte Agregado! Feliciades!</Alert.Heading>
                        <p>
                        Anotacion diaria con ID:<strong> {docID} </strong> agregada exitosamente
                        </p>
                    </Alert>
                    )
                    }

                <h2>Nueva Anotacion Diaria</h2>
                <div className='dashboard-content-search'>
                   
                </div>

                             <div className="card-header bg-info" style={{marginBottom:'5px'}}>
                                <h4 className="mb-0 text-white">Anotaciones de activadad diaria </h4>
                            </div>
                            
                            <form className="form-horizontal" onSubmit={handleSubmit}>
								<div className="mb-3">
                                    <label>Inspector</label>
                                    <input className="form-control" type="text" placeholder={user.fname+' '+user.lname} disabled/>
                                   
                                    <input className="form-control" type="hidden" value={user.id} />
                                </div>
								<div className="mb-3">
                                    <label>Numero de nota</label>
                                    <input className="form-control" type="text"  value={qtyAct} disabled/>
                                </div>
								 <div className="mb-3">
                                    <label>Seleccione villa o lote</label>
                                    <select value={newVid} className="form-select col-12" id="inlineFormCustomSelect" onChange={(e) => setNewVid(e.target.value)} required>
                                        <option value="">Elegir...</option>
                                        {villas!=null && (
                                            villas.map(vi=>(<option key={vi.id} value={JSON.stringify(vi)}>{vi.number}</option>))
                                        )}
                                    </select>
                                </div>
                                
								<div className="form-floating mb-3">
									<input type="date" className="form-control border border-success" placeholder="2022-07-21" onChange={(e) => setNewFecha(e.target.value)} value={newFecha} required />
									<label><i data-feather="lock" className="feather-sm  text-success fill-white me-2"></i><span className="border-start border-success ps-3">Fecha</span></label>
								</div>
								<div className="mb-3">
                                    <label>Tipo de Nota</label>
                                    <select value={newType} className="form-select col-12" id="inlineFormCustomSelect" onChange={(e) => setNewType(e.target.value)} required>
                                        <option value="" >Elegir...</option>
                                        <option value="1">Lluvia</option>
                                        <option value="2">Diaria</option>
                                        <option value="3">Vaciado</option>
										<option value="4">Multa</option>
										<option value="5">Atraso</option>
                                </select>
                                </div>								
                               
                                <div className="mb-3">
                                    <label>Notas y/o comentarios</label>
                                    <textarea className="form-control" rows="5" onChange={(e) => setNewMsg(e.target.value)} required defaultValue={newMsg}></textarea>
                                </div>
                                                                                       
                                <div className="mb-3">
                                    <label>Link de Google Drive</label>
                                    <input type="text" className="form-control" placeholder="Inserte su enlace aqui" onChange={(e) => setNewLink(e.target.value)} value={newLink}  />
                                    <span className="help-block"><small>Cualquier imagen o documento de soporte de la nota en google drive puede ser enlazado aqui.</small></span> </div>
								<div className="form-actions">
                                        <div className="card-body border-top">
                                            <button type="submit" className="btn btn-success rounded-pill px-4"> 
                                                <div className="d-flex align-items-center">
                                                    <i data-feather="save" className="feather-sm me-1 fill-icon"></i> Guardar
                                                </div>
                                            </button>
                                            <button type="button" className="btn btn-danger rounded-pill px-4 ms-2 text-white">Cancelar</button>
                                        </div>
                                    </div>
                            </form>
                        </div>
            </div>
        </div>
    </div>
    )
}

export default ReportForm;