import React from 'react';

import './styles.css';
import NotificationIcon from '../../../assets/icons/notification.svg';
import SettingsIcon from '../../../assets/icons/settings.svg';
//import NavigationMenu from "../TopMenu"



function DashboardHeader ({ btnText, onClick }) {
    return(
        <>
       { /*<NavigationMenu/>*/}
        <div className='dashbord-header-container'>
            {btnText && 
                <button className='dashbord-header-btn' onClick={onClick}>{btnText}</button>
            }

            <div className='dashbord-header-right'>
                <img 
                    src={NotificationIcon}
                    alt='notification-icon'
                    className='dashbord-header-icon' />
                <img 
                    src={SettingsIcon}
                    alt='settings-icon'
                    className='dashbord-header-icon' />
                <img
                    className='dashbord-header-avatar'
                    src='https://reqres.in/img/faces/9-image.jpg' alt="" />
            </div>
        </div>
        </>
    )
}

export default DashboardHeader;