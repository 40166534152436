import React, {useState, useCallback, useContext} from 'react';
import {useNavigate} from 'react-router-dom';
import DashboardHeader from '../../layout/DashboardHeader';

import '../styles.css';
import 'bootstrap/dist/css/bootstrap.css';

import {db} from '../../../firebase';
import {collection, addDoc, doc} from 'firebase/firestore';

import Alert from 'react-bootstrap/Alert';
//import Button from 'react-bootstrap/Button';
import AuthContext from '../../../store/auth-context';

import { useCollection } from '../../../hooks/useCollection';

const NewWeeklyReport = () => {
    const [showA, setShowA] = useState(false);
    const navigate = useNavigate();
    const handleOnClick = useCallback(() => navigate('/wreports', {replace: true}), [navigate]);
    const [docID, setdocID] = useState(false);
    const [newVid, setNewVid] = useState('')
    const [txt1, setTxt1] = useState('')
    const [txt2, setTxt2] = useState('')
    const [txt3, setTxt3] = useState('')


    const authCtx = useContext(AuthContext);
    let user; let query;

    if (authCtx.userdetails){
        user=JSON.parse(authCtx.userdetails);
        //setNewInspectorid(user.id);
        if(user.type==='2'){
            query =['active', '==',  true];
        }else{
            query =['inspectorid', '==',  user.id];
            //setNewInspectorid(user.id);
        }
       
     }else{
         user='';
         query =['active', '==',  true];
     }

     const handleSubmit = async (e) =>{
        e.preventDefault()
        const ref=collection(db, 'weeklyreport')
        let vi=JSON.parse(newVid)
        let result= await addDoc(ref, {
            date:new Date(),
            text1:txt1,
            text2: txt2,
            text3:txt3,
            userid: user.id,
            villaid:vi.id,
            villadetails:vi
          })
  
          if(result.id){
              setShowA(true);
              setdocID(result.id);

              setTxt1('')
              setTxt2('')
              setTxt3('')
          }

         
     }

     const {documents: villas} = useCollection(
        'villas-lots',  query
    )

     


     

    return(
        <div className='dashboard-content'>
        <DashboardHeader
            btnText="Ver reportes semanales" onClick={handleOnClick}/>


        <div className='dashboard-content-container'>
            <div className='dashboard-content-header'>
                

                <div className="card card-body">
                    {showA && (
                    <Alert variant="success" onClose={() => setShowA(false)} dismissible>
                    <Alert.Heading>Reporte Agregado! Feliciades!</Alert.Heading>
                        <p>
                        Reporte semanal con ID:<strong> {docID} </strong> agregado exitosamente
                        </p>
                    </Alert>
                    )
                    }

                <h2>Nuevo reporte semanal</h2>
                <div className='dashboard-content-search'>
                   
                </div>

                             <div className="card-header bg-info" style={{marginBottom:'5px'}}>
                                <h4 className="mb-0 text-white">reportes semanales por villas </h4>
                            </div>
                            
                            <form className="form-horizontal" onSubmit={handleSubmit}>
								
								 <div className="mb-3">
                                    <label>Seleccione villa o lote</label>
                                    <select value={newVid} className="form-select col-12" id="inlineFormCustomSelect" onChange={(e) => setNewVid(e.target.value)} required>
                                        <option value="">Elegir...</option>
                                        {villas!=null && (
                                            villas.map(vi=>(<option key={vi.id} value={JSON.stringify(vi)}>{vi.number}</option>))
                                        )}
                                    </select>
                                </div>

                                <div className="mb-3">
                                    <label>comentarios 1</label>
                                    <textarea className="form-control" rows="5" onChange={(e) => setTxt1(e.target.value)} required defaultValue={txt1}></textarea>
                                </div>
                                <div className="mb-3">
                                    <label>comentarios 2</label>
                                    <textarea className="form-control" rows="5" onChange={(e) => setTxt2(e.target.value)} required defaultValue={txt2}></textarea>
                                </div>
                                <div className="mb-3">
                                    <label>Actual Status</label>
                                    <textarea className="form-control" rows="5" onChange={(e) => setTxt3(e.target.value)} required defaultValue={txt3}></textarea>
                                </div>
                                                                                       
                                
								<div className="form-actions">
                                        <div className="card-body border-top">
                                            <button type="submit" className="btn btn-success rounded-pill px-4"> 
                                                <div className="d-flex align-items-center">
                                                    <i data-feather="save" className="feather-sm me-1 fill-icon"></i> Guardar
                                                </div>
                                            </button>
                                            <button type="button" className="btn btn-danger rounded-pill px-4 ms-2 text-white">Cancelar</button>
                                        </div>
                                    </div>
                            </form>
                        </div>
            </div>
        </div>
    </div>
    )
}

export default NewWeeklyReport