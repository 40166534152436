import React, {useState, useCallback, useEffect} from 'react';
import {useNavigate, useParams} from 'react-router-dom';
import DashboardHeader from '../../layout/DashboardHeader';

import '../styles.css';
import 'bootstrap/dist/css/bootstrap.css';

import {db} from '../../../firebase';
import {updateDoc, doc} from 'firebase/firestore';
import GetSingleDoc from '../../../firebase/functions';

import Alert from 'react-bootstrap/Alert';
//import Button from 'react-bootstrap/Button';



function EditStaff ({ btnText, onClick }) {
     // Get ID from URL
     const params = useParams();
     let getid=params.id;

    const navigate = useNavigate();
    const handleOnClick = useCallback(() => navigate('/viewstaff', {replace: true}), [navigate]);

    const [showA, setShowA] = useState(false);
    const [docID, setdocID] = useState(false);


    const [email, setEmail] = useState('')
    const [fname, setFname] = useState('')
    const [lname, setLname] = useState('')
    const [pswd, setPswd] = useState('')
    const [pho, setPho] = useState('')
    const [level, setLevel] = useState('')
    const [user, setUser] = useState('')

      //get current record in collection from FireStore
      let staff=null;
      staff=GetSingleDoc('staff', getid);
      useEffect(()=>{
        //let qtyAct;
        
        if(staff!==null){
            staff=JSON.parse(staff)
            //alert(actividad.comments)
            setEmail(staff.email)
            setFname(staff.fname)
            setLname(staff.lname)
            setPswd(staff.password)
            setPho(staff.phone)
            setLevel(staff.type)
            setUser(staff.username)
        }
    },[staff])
    

    const handleSubmit = async (e) => {
        e.preventDefault()
        //console.log(newBook)
        //const ref=collection(db, 'staff')
        const ref = doc(db, "staff", getid);
    
       /*let result= await addDoc(ref, {
          active: true,
          createddate: new Date(),
          email:email,
          fname:fname,
          lname: lname,
          password:pswd,
          phone:pho,
          type:level,
          username:user
        })*/
        let result= await updateDoc(ref, {
          email:email,
          fname:fname,
          lname: lname,
          password:pswd,
          phone:pho,
          type:level,
          username:user
        })

        setShowA(true);
        //console.log(result.id);
        /*setEmail('')
        setFname('')
        setLname('')
        setPswd('')
        setPho('')
        setLevel('')
        setUser('')*/
      }

    return(
        <div className='dashboard-content'>
        <DashboardHeader
            btnText="Ver Inspectores" onClick={handleOnClick}/>


        <div className='dashboard-content-container'>
            <div className='dashboard-content-header'>
                

                <div className="card card-body">
                    {showA && (
                    <Alert variant="success" onClose={() => setShowA(false)} dismissible>
                    <Alert.Heading>Staff cambiado! Feliciades!</Alert.Heading>
                        <p>
                            Staff con ID:<strong> {getid} </strong> cambiado exitosamente
                        </p>
                    </Alert>
                    )
                    }

                <h2>Editar Staff</h2>
                <div className='dashboard-content-search'>
                   
                </div>

                             <div className="card-header bg-danger" style={{marginBottom:'5px'}}>
                                <h4 className="mb-0 text-white">Formulario para cambiar inspector o usuario</h4>
                            </div>
                            
                            <form className="form-horizontal" onSubmit={handleSubmit}>
								<div className="mb-3">
                                    <label>Nombres</label>
                                    <input className="form-control" type="text" placeholder="Solo nombres" onChange={(e) => setFname(e.target.value)} value={fname} required/>
                                </div>
								<div className="mb-3">
                                    <label>Apellidos</label>
                                    <input className="form-control" type="text" placeholder="Solo apellidos" onChange={(e) => setLname(e.target.value)} value={lname} required />
                                </div>
                                <div className="mb-3">
                                    <label>Correo Electronico</label>
                                    <input className="form-control" type="email" placeholder="email" onChange={(e) => setEmail(e.target.value)} value={email} required />
                                </div>
                                <div className="mb-3">
                                    <label>Telefono</label>
                                    <input className="form-control" type="text" placeholder="Numero de telefono" onChange={(e) => setPho(e.target.value)} value={pho} required />
                                </div>
                                <div className="mb-3">
                                    <label>Usuario</label>
                                    <input className="form-control" type="text" placeholder="Nombre de usuario" onChange={(e) => setUser(e.target.value)} value={user} required />
                                </div>
                                <div className="mb-3">
                                    <label>Contraseña</label>
                                    <input className="form-control" type="password" placeholder="" onChange={(e) => setPswd(e.target.value)} value={pswd} required />
                                </div>
                                
								<div className="mb-3">
                                    <label>Nivel de acceso</label>
                                    <select value={level} className="form-select col-12" id="inlineFormCustomSelect" onChange={(e) => setLevel(e.target.value)} required>
                                        <option >Elegir...</option>
                                        <option value="1">Inspector</option>
                                        <option value="2">Administrador</option>
                                </select>
                                </div>								
								<div className="form-actions">
                                        <div className="card-body border-top">
                                            <button type="submit" className="btn btn-primary rounded-pill px-4"> 
                                                <div className="d-flex align-items-center">
                                                    <i data-feather="save" className="feather-sm me-1 fill-icon"></i> Actualizar
                                                </div>
                                            </button>
                                            <button type="button" className="btn btn-danger rounded-pill px-4 ms-2 text-white">Cancelar</button>
                                        </div>
                                    </div>
                            </form>
                        </div>
            </div>
        </div>
    </div>
    )
}

export default EditStaff;