import React, {useState, useEffect, useCallback, useContext} from 'react';
import {useNavigate} from 'react-router-dom';
import DashboardHeader from '../../layout/DashboardHeader';
import AuthContext from '../../../store/auth-context';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Carousel from 'react-bootstrap/Carousel';
import 'mdb-ui-kit/css/mdb.min.css';
import './style.css';

import Slide1 from '../../../assets/images/1.svg';
import Slide2 from '../../../assets/images/2.svg';
import Slide3 from '../../../assets/images/3.svg';

const LotsPage = ()=>{
    const navigate = useNavigate();
    const handleOnClick = useCallback(() => navigate('/activity', {replace: true}), [navigate]);

    const handleReports = useCallback(() => navigate('/activities', {replace: true}), [navigate]);
    const handleVillas = useCallback(() => navigate('/viewlots', {replace: true}), [navigate]);
    const handleBuilders = useCallback(() => navigate('/viewbuilders', {replace: true}), [navigate]);
    const handleInspectors = useCallback(() => navigate('/viewstaff', {replace: true}), [navigate]);
    const handleRains = useCallback(() => navigate('/legalrain', {replace: true}), [navigate]);

    const authCtx = useContext(AuthContext);

    const logoutHandler = () => {
        authCtx.logout();
        // optional: redirect the user
    };

    let user;

    if (authCtx.userdetails){
       user=JSON.parse(authCtx.userdetails);
    }else{
        user='';
    }

    //alert(user.type)
    //console.log('user', user);

    return(
        <>
        <DashboardHeader btnText="Crear nuevo reporte" onClick={handleOnClick}/>

        <div><h1 style={{textAlign:'center'}}> Bienvenido <span style={{color:'red'}}> {user.fname}  {user.lname}</span> a la APP Construyen2</h1></div>
        <Container>
      
        <div id="carouselBasicExample" className="carousel slide carousel-fade" data-mdb-ride="carousel">

        <div className="carousel-indicators">
            <button
            type="button"
            data-mdb-target="#carouselBasicExample"
            data-mdb-slide-to="0"
            className="active"
            aria-current="true"
            aria-label="Slide 1"
            ></button>
            <button
            type="button"
            data-mdb-target="#carouselBasicExample"
            data-mdb-slide-to="1"
            aria-label="Slide 2"
            ></button>
            <button
            type="button"
            data-mdb-target="#carouselBasicExample"
            data-mdb-slide-to="2"
            aria-label="Slide 3"
            ></button>
        </div>


        <div className="carousel-inner">

            <div className="carousel-item active">
            <img src="https://mdbcdn.b-cdn.net/img/Photos/Slides/img%20(15).webp" className="d-block w-100" alt="Sunset Over the City"/>
            <div className="carousel-caption d-none d-md-block">
                <h5>Cree sus anotaciones diarias</h5>
                <p>Nulla vitae elit libero, a pharetra augue mollis interdum.</p>
            </div>
            </div>


            <div className="carousel-item">
            <img src="https://mdbcdn.b-cdn.net/img/Photos/Slides/img%20(22).webp" className="d-block w-100" alt="Canyon at Nigh"/>
            <div className="carousel-caption d-none d-md-block">
                <h5>Vea el historial de reportes</h5>
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
            </div>
            </div>

            <div className="carousel-item">
            <img src="https://mdbcdn.b-cdn.net/img/Photos/Slides/img%20(23).webp" className="d-block w-100" alt="Cliff Above a Stormy Sea"/>
            <div className="carousel-caption d-none d-md-block">
                <h5>Mida el progreso de la villa</h5>
                <p>Praesent commodo cursus magna, vel scelerisque nisl consectetur.</p>
            </div>
            </div>
        </div>

        <button className="carousel-control-prev" type="button" data-mdb-target="#carouselBasicExample" data-mdb-slide="prev">
            <span className="carousel-control-prev-icon" aria-hidden="true"></span>
            <span className="visually-hidden">Previous</span>
        </button>
        <button className="carousel-control-next" type="button" data-mdb-target="#carouselBasicExample" data-mdb-slide="next">
            <span className="carousel-control-next-icon" aria-hidden="true"></span>
            <span className="visually-hidden">Next</span>
        </button>
        </div>



            <Row className="justify-content-md-center">
                <Col className="border border-primary" onClick={handleReports}>
                    <i className="fa-sharp fa-solid fa-notebook fa-6x"></i><br/>
                    Reportes
                    </Col>
                {user.type==='2' &&(
                    <>
                <Col className="border border-secondary" onClick={handleVillas}>
                    <i className="fa-solid fa-house-building fa-6x"></i><br/>
                    Villas
                    </Col>
               
                <Col className="border border-success" onClick={handleBuilders}>
                    <i className="fa-sharp fa-solid fa-triangle-person-digging fa-6x"></i><br/>
                    Constructores</Col>
                    </>
                )}
            </Row>

            <Row>
            {user.type==='2' &&(
                <>
                <Col className="border border-danger" onClick={handleInspectors}>
                    <i className="fa-light fa-user-helmet-safety fa-6x"></i><br/>
                    Inspectores</Col>
                <Col className="border border-warning" onClick={handleRains}>
                    <i className="fa-regular fa-cloud-showers-water fa-6x"></i><br/>
                    lluvia Legal</Col>
                </>
            )}

                <Col className="border border-info" onClick={logoutHandler}>
                    <i className="fa-sharp fa-solid fa-right-from-bracket fa-6x"></i><br/>
                    Salir</Col>
            </Row>
        </Container>
        </>
    )
}

export default LotsPage;