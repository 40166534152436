
import DashboardIcon from '../../assets/icons/dashboard.svg';
import ShippingIcon from '../../assets/icons/shipping.svg';
import ProductIcon from '../../assets/icons/product.svg';





const sidebar_menu = [
    {
        id: 1,
        icon: DashboardIcon,
        path: '/',
        title: 'Inicio',
    },
    {
        id: 2,
        icon: ProductIcon,
        path: '/activities',
        title: 'Ver Reportes',
    },
    {
        id: 3,
        icon: ShippingIcon,
        path: '/activity',
        title: 'Nuevo Reporte',
    }
]


export default sidebar_menu;