import React, {useEffect, useState} from 'react';
import { useLocation } from 'react-router-dom';

import SideBarItem from './sidebar-item';

import './styles.css';
import logo from '../../../assets/images/white-logo.png';
import LogoutIcon from '../../../assets/icons/logout.svg';

import ThemeProvider from 'react-bootstrap/ThemeProvider';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';


function SideBar ({ menu }) {
    const location = useLocation();

    const [active, setActive] = useState(1);

    useEffect(() => {
        menu.forEach(element => {
            if (location.pathname === element.path) {
                setActive(element.id);
            }
        });
    }, [location.pathname])

    const __navigate = (id) => {
        setActive(id);
    }

    return(
        <ThemeProvider
            breakpoints={['xxxl', 'xxl', 'xl', 'lg', 'md']}
            minBreakpoint="md"
            >
        
            <Row>
                <Col lg="auto">     
                    <nav className='sidebar hidden-mobile'>
                        <div className='sidebar-container'>
                            <div className='sidebar-logo-container'>
                                <img
                                    src={logo}
                                    alt="logo" />
                            </div>

                            <div className='sidebar-container'>
                                <div className='sidebar-items'>
                                    {menu.map((item, index) => (
                                        <div key={index} onClick={() => __navigate(item.id)}>
                                            <SideBarItem
                                                active={item.id === active}
                                                item={item} />
                                        </div>
                                    ))}
                                </div>

                                <div className='sidebar-footer'>
                                    <span className='sidebar-item-label'>Logout</span>
                                    <img 
                                        src={LogoutIcon}
                                        alt='icon-logout'
                                        className='sidebar-item-icon' />
                                </div>
                            </div>
                        </div>
                    </nav>
                </Col>
            </Row>
           
    </ThemeProvider>
    )
}

export default SideBar;