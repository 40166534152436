import React, {useState, useEffect, useCallback} from 'react';
import {useNavigate, Link} from 'react-router-dom';

import DashboardHeader from '../../layout/DashboardHeader';

import '../styles.css';


import { useCollection } from '../../../hooks/useCollection';
import Table from 'react-bootstrap/Table';
import Badge from 'react-bootstrap/Badge';

import { db } from '../../../firebase'
import { doc, deleteDoc } from 'firebase/firestore'
import { async } from '@firebase/util';

function Orders () {
   
    const navigate = useNavigate();
    const handleOnClick = useCallback(() => navigate('/staff', {replace: true}), [navigate]);
    
    const {documents: orders} = useCollection(
        'staff',
        ['active', '==', true]
    )

    console.log(orders);

    const handleDelete = async(id) => {
        if (window.confirm("Esta seguro de borrar este elemento?")) {
            const refDoc = doc(db, 'staff', id)
            await deleteDoc(refDoc)
            alert('Elemento Borrado '+id);
        }
    }

    const ChangeHandle = () => {
        //alert('change pressed')

    }


    return(
        <div className='dashboard-content'>
            <DashboardHeader
                btnText="Crear nuevo Inspector" onClick={handleOnClick}/>

            <div className='dashboard-content-container'>
              
                    <h2>Lista de inspectores y administradores</h2>
                   
                   
                            <Table striped bordered hover>
                                <thead>
                                    <tr>
                                    <th>Nombre</th>
                                    <th>Apellidos</th>
                                    <th>Correo</th>
                                    <th>Telefono</th>
                                    <th>Usuario</th>
                                    <th>Nivel</th>
                                    <th>Acciones</th>
                                    </tr>
                                </thead>
                                {orders!=null ? (
                                <tbody>
                                    {orders.map(order=>(
                                    <tr key={order.id}>
                                    <td>{order.fname}</td>
                                    <td>{order.lname}</td>
                                    <td>{order.email}</td>
                                    <td>{order.phone}</td>
                                    <td>{order.username}</td>
                                    <td>{ order.type==='1' ? ('Inspector') : ('Administrador') }</td>
                                    <td> <Link to={order.id} onClick={ChangeHandle}>  <Badge pill bg="primary">Editar</Badge></Link> | <Link to="/viewstaff" onClick={ () => handleDelete(order.id)}> <Badge pill bg="danger">Borrar</Badge></Link></td>
                                    </tr>
                                   )
                                 )}
                                </tbody>
                                )
                                : (
                                    <tbody><tr><td colSpan={4}>No Data</td></tr></tbody>
                                )
                                }
                            </Table>

            </div>
        </div>
    )
}


export default Orders;
