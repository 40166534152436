import { useState, useRef, useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import Container from 'react-bootstrap/Container';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import AuthContext from '../../../store/auth-context';


import { collection, query, where, onSnapshot } from "firebase/firestore";  
import { db } from '../../../firebase'
import { async } from '@firebase/util';
import Alert from 'react-bootstrap/Alert';
import Spinner from 'react-bootstrap/Spinner';

import * as mdb from 'mdb-ui-kit'; // lib
import { Input } from 'mdb-ui-kit'; // module
import 'mdb-ui-kit/css/mdb.min.css';
import './styleMDB.css'



const AuthPage = () => {
    const authCtx = useContext(AuthContext);

    const [document, setDocuments] = useState(null);
    const [errorLogin, setErrorLogin] = useState(false);
    const [loading, setLoading] = useState(false);
    const [errorMsg, setErrorMsg] = useState('');
    const history = useNavigate();
    const emailInputRef = useRef();
    const passwordInputRef = useRef();
   
        useEffect(() => {
            //alert("USEFFECT!!!!");
            if (document !== null) {
                //alert(document)
                const expirationTime = new Date(
                    new Date().getTime()  + 13500000
                );
                const expiration= expirationTime.toISOString();
                    //alert("docyumen.id:  " + JSON.stringify(document));
                    let token = document.id;
            
                    authCtx.login(token, expiration, JSON.stringify(document));

                    history('/');
                    setLoading(false)
            }
        }, [document]) 



    const submitHandler = (event) => {
        setLoading(true)
        event.preventDefault();
        let user=emailInputRef.current.value;
        let pswd=passwordInputRef.current.value;

        let database=collection(db, 'staff');

        const q=query(database, where("password", "==", pswd), where("username", "==", user));

        onSnapshot(q, (snapshot)=>{
            let results=[]
            snapshot.docs.forEach(doc=>{
                results.push({...doc.data(), id:doc.id})
            })
           
            console.log(results[0])
            if(results[0]===undefined){
                //alert('No result')
                setErrorLogin(true)
                setErrorMsg('Usuario o contraseña incorrectos, intente nuevamente.')
                setLoading(false)
            }else{
                setDocuments(results[0])
            }

        });
       
    }


    return (
        <section className="h-100 gradient-form" style={{backgroundColor: '#eee'}}>
            <div className="container py-5 h-100">
                <div className="row d-flex justify-content-center align-items-center h-100">
                <div className="col-xl-10">
                    <div className="card rounded-3 text-black">
                    <div className="row g-0">
                        <div className="col-lg-6">
                        <div className="card-body p-md-5 mx-md-4">

                            <div className="text-center">
                            <img src="https://mdbcdn.b-cdn.net/img/Photos/new-templates/bootstrap-login-form/lotus.webp" 
                                style={{width: '185px'}} alt="logo" />
                            <h4 className="mt-1 mb-5 pb-1">Esta es la app de reportes <strong>Construyen2</strong></h4>
                            </div>

                            <form onSubmit={submitHandler}>
                            <p>Por favor inicie sesion en su cuenta</p>
                            {errorLogin && (
                                <Alert variant="danger" onClose={() => setErrorLogin(false)} dismissible>
                                    <Alert.Heading>Error! Verifique lo siguiente!</Alert.Heading>
                                    <p>
                                    {errorMsg}
                                    </p>
                                </Alert>
                                )}
                            <div className="form-outline mb-4">
                                <input type="text" id="form2Example11" className="form-control"
                                placeholder="Phone number or email address" ref={emailInputRef} required />
                                <label className="form-label" htmlFor="form2Example11">Nombre de Usuario</label>
                            </div>

                            <div className="form-outline mb-4">
                                <input type="password" id="form2Example22" className="form-control" ref={passwordInputRef} required/>
                                <label className="form-label" htmlFor="form2Example22">Contraseña</label>
                            </div>

                            <div className="text-center pt-1 mb-5 pb-1">
                                {!loading && (
                                    <button className="btn btn-primary btn-block fa-lg gradient-custom-2 mb-3" type="submit">Log
                                    in</button>
                                )}
                                {loading && (
                                    <Spinner animation="border" variant="danger" />
                                )}
                                {/*<a className="text-muted" href="#!">Forgot password?</a>*/}
                            </div>

                            <div className="d-flex align-items-center justify-content-center pb-4">
                                {/*<p className="mb-0 me-2">Don't have an account?</p>
                                <button type="button" className="btn btn-outline-danger">Create new</button>*/}
                            </div>

                            </form>

                        </div>
                        </div>
                        <div className="col-lg-6 d-flex align-items-center gradient-custom-2">
                        <div className="text-white px-3 py-4 p-md-5 mx-md-4">
                            <h4 className="mb-4">Bienvenidos a la aplicación de reportes.</h4>
                            <p className="small mb-0">Con la ayuda de esta aplicación, queremos facilitar el intercambio de información de construcción entre los equipos. Si tienen problema con la aplicación, favor dirigirse hacia Kevin.</p>
                        </div>
                        </div>
                    </div>
                    </div>
                </div>
                </div>
            </div>
      </section>
    )
  };
  
  export default AuthPage;