import { initializeApp } from "firebase/app";
import { getFirestore } from "@firebase/firestore";
import { getAuth } from 'firebase/auth'

const firebaseConfig = {
  apiKey: "AIzaSyBpnTv1l0nxwbv5FwqfIiLcZUIIbT7SCN4",
  authDomain: "construction-2f2a0.firebaseapp.com",
  projectId: "construction-2f2a0",
  storageBucket: "construction-2f2a0.appspot.com",
  messagingSenderId: "51738975858",
  appId: "1:51738975858:web:15daf8d7a8001b9bd0eceb",
  measurementId: "G-CMX9R3H6HC"
};

//init firebase
initializeApp(firebaseConfig);

//init firestore
const db= getFirestore();

//init firebase auth
const auth = getAuth()

export { db, auth } 
//firebase 9 style different than 8