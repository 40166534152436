
import React, {useState, useCallback, useContext, useEffect} from 'react';
import {useNavigate, useParams} from 'react-router-dom';
import DashboardHeader from '../../layout/DashboardHeader';

import '../styles.css';
import 'bootstrap/dist/css/bootstrap.css';

import {db} from '../../../firebase';
import {updateDoc, doc} from 'firebase/firestore';

import Alert from 'react-bootstrap/Alert';
//import Button from 'react-bootstrap/Button';
import AuthContext from '../../../store/auth-context';

import { useCollection } from '../../../hooks/useCollection';
import GetSingleDoc from '../../../firebase/functions';


const EditWeeklyReport = () =>{

    // Get ID from URL
    const params = useParams();
    let getid=params.id;
    let report=null
    report=GetSingleDoc('weeklyreport', getid);

    const [showA, setShowA] = useState(false);
    const navigate = useNavigate();
    const handleOnClick = useCallback(() => navigate('/wreports', {replace: true}), [navigate]);
    const [docID, setdocID] = useState(false);
    const [newVid, setNewVid] = useState('')
    const [txt1, setTxt1] = useState('')
    const [txt2, setTxt2] = useState('')
    const [txt3, setTxt3] = useState('')

    useEffect(()=>{
        //let qtyAct;
       // alert(report)
        if(report!==null){
            report=JSON.parse(report)
            //alert(actividad.comments)
            setdocID(report.id)
            setNewVid(report.villadetails)
            setTxt1(report.text1)
            setTxt2(report.text2)
            setTxt3(report.text3)
        }
    },[report])


    const authCtx = useContext(AuthContext);
    let user; let query;

    if (authCtx.userdetails){
        user=JSON.parse(authCtx.userdetails);
     }

     const handleSubmit = async (e) =>{
        e.preventDefault()
        
        
        //const ref=collection(db, 'weeklyreport')
        const ref = doc(db, "weeklyreport", getid);

        let vi=JSON.parse(newVid)

        let result= await updateDoc(ref, {
            date:new Date(),
            text1:txt1,
            text2: txt2,
            text3:txt3,
            userid: user.id,
            villaid:vi.id,
            villadetails:vi
          })
        setShowA(true);
     }

     const {documents: villas} = useCollection(
        'villas-lots',  query
    )


    return(
        <div className='dashboard-content'>
        <DashboardHeader
            btnText="Ver reportes semanales" onClick={handleOnClick}/>


        <div className='dashboard-content-container'>
            <div className='dashboard-content-header'>
                

                <div className="card card-body">
                    {showA && (
                    <Alert variant="success" onClose={() => setShowA(false)} dismissible>
                    <Alert.Heading>Reporte Cambiado! Feliciades!</Alert.Heading>
                        <p>
                        Reporte semanal con ID:<strong> {docID} </strong> cambiado exitosamente
                        </p>
                    </Alert>
                    )
                    }

                <h2>Cambiar reporte semanal</h2>
                <div className='dashboard-content-search'>
                   
                </div>

                             <div className="card-header bg-danger" style={{marginBottom:'5px'}}>
                                <h4 className="mb-0 text-white">reportes semanales por villas </h4>
                            </div>
                            
                            <form className="form-horizontal" onSubmit={handleSubmit}>
								
								 <div className="mb-3">
                                    <label>Seleccione villa o lote</label>
                                    <select value={newVid} className="form-select col-12" id="inlineFormCustomSelect" onChange={(e) => setNewVid(e.target.value)} required>
                                        <option value="">Elegir...</option>
                                        {villas!=null && (
                                            villas.map(vi=>(<option key={vi.id} value={JSON.stringify(vi)}>{vi.number}</option>))
                                        )}
                                    </select>
                                </div>

                                <div className="mb-3">
                                    <label>comentarios 1</label>
                                    <textarea className="form-control" rows="5" onChange={(e) => setTxt1(e.target.value)} required defaultValue={txt1}></textarea>
                                </div>
                                <div className="mb-3">
                                    <label>comentarios 2</label>
                                    <textarea className="form-control" rows="5" onChange={(e) => setTxt2(e.target.value)} required defaultValue={txt2}></textarea>
                                </div>
                                <div className="mb-3">
                                    <label>Actual Status</label>
                                    <textarea className="form-control" rows="5" onChange={(e) => setTxt3(e.target.value)} required defaultValue={txt3}></textarea>
                                </div>
                                                                                       
                                
								<div className="form-actions">
                                        <div className="card-body border-top">
                                            <button type="submit" className="btn btn-primary rounded-pill px-4"> 
                                                <div className="d-flex align-items-center">
                                                    <i data-feather="save" className="feather-sm me-1 fill-icon"></i> Cambiar
                                                </div>
                                            </button>
                                            <button type="button" className="btn btn-info rounded-pill px-4 ms-2 text-white">Cancelar</button>
                                        </div>
                                    </div>
                            </form>
                        </div>
            </div>
        </div>
    </div>
    )
}

export default EditWeeklyReport