import React, {useState, useCallback, useContext, useEffect} from 'react';
import {useNavigate, useParams} from 'react-router-dom';
import DashboardHeader from '../../layout/DashboardHeader';

import '../styles.css';
import 'bootstrap/dist/css/bootstrap.css';

import {db} from '../../../firebase';
import GetSingleDoc from '../../../firebase/functions';
import {collection, updateDoc, doc} from 'firebase/firestore';

import Alert from 'react-bootstrap/Alert';
//import Button from 'react-bootstrap/Button';
import AuthContext from '../../../store/auth-context';

import { useCollection } from '../../../hooks/useCollection';




function ReportForm ({ btnText, onClick }) {
    // Get ID from URL
    const params = useParams();
    let getid=params.id;
    //alert(params.id);
    const [newLink, setNewLink] = useState('')
    const [newVid, setNewVid] = useState('')
    const [newType, setNewType] = useState('')
    const [newNumber, setNewNumber] = useState('')
   const [inspID, setNewInspID]=useState('')
    const [newFecha, setNewFecha] = useState('')
    const [newMsg, setNewMsg] = useState('')
    const [showAlert, setShowAlert] = useState(false)
    const [villaInfo, setVillaInfo] = useState('')
   
    let actividad=null;
    //let qtyAct;
    actividad=GetSingleDoc('activities', getid);

    
    useEffect(()=>{
        //let qtyAct;
        
        if(actividad!==null){
            actividad=JSON.parse(actividad)
            //alert(actividad.comments)
            setVillaInfo(actividad.villadetails)
            setNewLink(actividad.link)
            setNewVid(actividad.villaid)
            setNewType(actividad.type)
            setNewFecha(actividad.fecha)
            setNewMsg(actividad.comments)
            setNewNumber(actividad.number)
            setNewInspID(actividad.inspectorid)
           
        }
    },[actividad])
   

    //const [showA, setShowA] = useState(false);

    const authCtx = useContext(AuthContext);
    let user; let query;

    if (authCtx.userdetails){
        user=JSON.parse(authCtx.userdetails);
        
        if(user.type==='2'){
            query =['active', '==',  true];
        }else{
            query =['inspectorid', '==',  user.id];
            //setNewInspectorid(user.id);
        }

     }else{
         user='';
         query =['active', '==',  true];
     }

     const {documents: villas} = useCollection(
        'villas-lots',  query
    )
    
    const navigate = useNavigate();
    const handleOnClick = useCallback(() => navigate('/activities', {replace: true}), [navigate]);

    
    
//alert(villaInfo.id)

    const handleSubmit = async (e) => {
        e.preventDefault()
        let villa=villaInfo
        if(villa){
            setNewVid(villa.id)
            //alert(villa.id)
        }

        const ref = doc(db, "activities", getid);

       let result= await updateDoc(ref, {
          active: true,
          comments: newMsg,
          fecha:newFecha,
          inspectorid: inspID,
          number:newNumber,
          type:newType,
          villaid:villa.id,
          villadetails:villaInfo,
          link:newLink
        })
        
        //alert(result)
        setShowAlert(true)
        

      }

    return(
        <div className='dashboard-content'>
        <DashboardHeader
            btnText="Ver reportes" onClick={handleOnClick}/>


        <div className='dashboard-content-container'>
            <div className='dashboard-content-header'>
                

                <div className="card card-body">
                    {showAlert && (
                    <Alert variant="success" onClose={() => setShowAlert(false)} dismissible>
                    <Alert.Heading>Reporte Cambiado! Feliciades!</Alert.Heading>
                        <p>
                        Anotacion diaria con ID:<strong> {getid} </strong> Cambiada con exito.
                        </p>
                    </Alert>
                    )
                    }

                <h2 >Cambiar Anotacion Diaria <span className="bg-danger" style={{color:'white', fontSize:'10px'}}>{getid}</span></h2>
                <div className='dashboard-content-search'>
                   {/*actividad!==null && (
                           // actividad.comments
                   )*/} 
                </div>

                             <div className="card-header bg-danger" style={{marginBottom:'5px'}}>
                                <h4 className="mb-0 text-white">Anotaciones de activadad diaria </h4>
                            </div>
                            
                            <form className="form-horizontal" onSubmit={handleSubmit}>
								<div className="mb-3">
                                    <label>Inspector</label>
                                    <input className="form-control" type="text" placeholder={user.fname+' '+user.lname} disabled/>
                                   
                                    <input className="form-control" type="hidden" value={user.id} />
                                </div>
								<div className="mb-3">
                                    <label>Numero de nota</label>
                                    <input className="form-control" type="text"  value={newNumber} disabled/>
                                </div>
								 <div className="mb-3">
                                    {console.log('STATEVARIABLE:', villaInfo)}
                                    {/*
                                        {villas!=null && (
                                            villas.map(vi=>(
                                                <div key={vi.id} >{vi.number}{
                                                villaInfo && (
                                                    vi.id===villaInfo.id ?
                                                     (<>array: {vi.id} default: {vi.number} <span>si</span>{/*setVillaInfo(vi)}</>)
                                                     :
                                                     (<>array: {vi.id} default: {vi.number} <span>no</span></>)
                                                )
                                                
                                                }
                                                
                                                </div>)
                                                )
                                        )}
                                    */}
                                    <label>Seleccione villa o lote</label>
                                    <select  value={villaInfo.number} className="form-select col-12" id="inlineFormCustomSelect" onChange={(e) => {
                                        villas.map((element) => {
                                            if (element.number === e.target.value) {
                                                setVillaInfo(element)
                                            }
                                        })
                                         }}    required>
                                        <option value="">Elegir...</option>
                                        {villas!=null && (
                                            villas.map(vi=>(<option key={vi.id} value={vi.number}>{vi.number}{/*console.log('select value:',JSON.stringify(vi) )*/}</option>))
                                        )}
                                    </select>
                                </div>
                                
								<div className="form-floating mb-3">
									<input type="date" className="form-control border border-success" placeholder="2022-07-21" onChange={(e) => setNewFecha(e.target.value)} value={newFecha} required />
									<label><i data-feather="lock" className="feather-sm  text-success fill-white me-2"></i><span className="border-start border-success ps-3">Fecha</span></label>
								</div>
								<div className="mb-3">
                                    <label>Tipo de Nota</label>
                                    <select value={newType} className="form-select col-12" id="inlineFormCustomSelect" onChange={(e) => setNewType(e.target.value)} required>
                                        <option value="" >Elegir...</option>
                                        <option value="1">Lluvia</option>
                                        <option value="2">Diaria</option>
                                        <option value="3">Vaciado</option>
										<option value="4">Multa</option>
										<option value="5">Atraso</option>
                                </select>
                                </div>								
                               
                                <div className="mb-3">
                                    <label>Notas y/o comentarios</label>
                                    <textarea className="form-control" rows="5" onChange={(e) => setNewMsg(e.target.value)} required defaultValue={newMsg}>
                                   
                                    </textarea>
                                </div>
                                                                                       
                                <div className="mb-3">
                                    <label>Link de Google Drive</label>
                                    <input type="text" className="form-control" placeholder="Inserte su enlace aqui" onChange={(e) => setNewLink(e.target.value)} value={newLink}  />
                                    <span className="help-block"><small>Cualquier imagen o documento de soporte de la nota en google drive puede ser enlazado aqui.</small></span> </div>
								<div className="form-actions">
                                        <div className="card-body border-top">
                                            <button type="submit" className="btn btn-success rounded-pill px-4"> 
                                                <div className="d-flex align-items-center">
                                                    <i data-feather="save" className="feather-sm me-1 fill-icon"></i> Guardar
                                                </div>
                                            </button>
                                            <button type="button" className="btn btn-danger rounded-pill px-4 ms-2 text-white">Cancelar</button>
                                        </div>
                                    </div>
                            </form>
                        </div>
            </div>
        </div>
    </div>
    )
}

export default ReportForm;