import React, {useState, useEffect, useCallback} from 'react';
import {useNavigate, Link} from 'react-router-dom';

import DashboardHeader from '../../layout/DashboardHeader';

import '../styles.css';


import { useCollection } from '../../../hooks/useCollection';
import Table from 'react-bootstrap/Table';
import Badge from 'react-bootstrap/Badge';

import { db } from '../../../firebase'
import { doc, deleteDoc, getDoc } from 'firebase/firestore'
import { async } from '@firebase/util';

import Spinner from 'react-bootstrap/Spinner';
import Button from 'react-bootstrap/Button';

import { collection, onSnapshot, query, where } from "firebase/firestore";

function Orders () {
   
    const navigate = useNavigate();
    const handleOnClick = useCallback(() => navigate('/lots', {replace: true}), [navigate]);
    
    const {documents: orders} = useCollection(
        'villas-lots',
        ['active', '==', true]
    )

    //console.log(orders);

    const handleDelete = async(id) => {
        if (window.confirm("Esta seguro de borrar este elemento?")) {
            const refDoc = doc(db, 'villas-lots', id)
            await deleteDoc(refDoc)
            alert('Elemento Borrado '+id);
        }
    }

    const ChangeHandle = () => {
        //alert('change pressed')

    }

    const ShowBuilder = (id) => {

        const docRef = doc(db, 'builder', id)
            getDoc(docRef)
            .then((doc)=>{
                let results=[]
                results.push({...doc.data(), id:doc.id})
                localStorage.setItem(id, JSON.stringify(results[0]));
            })    
        
        const insp=JSON.parse(localStorage.getItem(id));
        
        let details;
        if(insp){
            details=insp.name;
        }else{
            //window.location.reload();
        }
        
    return details;
    }

    const ShowInspector = (id) => {

        const docRef = doc(db, 'staff', id)
            getDoc(docRef)
            .then((doc)=>{
                let results=[]
                results.push({...doc.data(), id:doc.id})
                localStorage.setItem(id, JSON.stringify(results[0]));
            })    
        
        const insp=JSON.parse(localStorage.getItem(id));
        
        let details;
        if(insp){
            details=insp.fname+' '+insp.lname;
        }else{
           // window.location.reload();
        }
        
    return details;
    }



    return(
        <div className='dashboard-content'>
            <DashboardHeader
                btnText="Crear nuevo lote" onClick={handleOnClick}/>

            <div className='dashboard-content-container'>
              
                    <h2>Lista de villas</h2>
                  
                   {orders ? (
                            <Table striped bordered hover>
                                <thead>
                                    <tr>
                                    <th>#</th>
                                    <th>M2</th>
                                    <th>Inspector</th>
                                    <th>Fase</th>
                                    <th>Fecha de inicio</th>
                                    <th>Fecha de terminada</th>
                                    <th>Fecha Real de Inicio</th>
                                    <th>Fecha Real de terminada</th>
                                    <th>Constructor</th>
                                    <th>Acciones</th>
                                    </tr>
                                </thead>
                                {orders!=null ? (
                                <tbody>
                                    {orders.map(order=>(
                                    <tr key={order.id}>
                                    <td>{order.number}</td>
                                    <td>{order.m2}</td>
                                    <td>{ ShowInspector(order.inspectorid)}</td>
                                    <td>{order.phase}</td>
                                    <td>{order.startdate}</td>
                                    <td>{order.enddate}</td>
                                    <td>{order.realstart}</td>
                                    <td>{order.realend}</td>
                                    <td>{ShowBuilder(order.builderid)}</td>
                                    <td> <Link to={order.id} onClick={ChangeHandle}>  <Badge pill bg="primary">Editar</Badge></Link> | <Link to="/viewlots" onClick={ () => handleDelete(order.id)}> <Badge pill bg="danger">Borrar</Badge></Link></td>
                                    </tr>
                                   )
                                 )}
                                </tbody>
                                )
                                : (
                                    <tbody><tr><td colSpan={4}>No Data</td></tr></tbody>
                                )
                                }
                            </Table>) : (
                            <>
                            <Button variant="primary" disabled>
                                <Spinner
                                as="span"
                                animation="border"
                                size="sm"
                                role="status"
                                aria-hidden="true"
                                /> 
                                 Loading...
                            </Button>
                             {/*<Spinner animation="border" variant="danger" />*/}
                             </>
                            )}

            </div>
        </div>
    )
}


export default Orders;
