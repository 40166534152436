import React, {useState, useContext} from 'react';

import '../styles.css';
import 'bootstrap/dist/css/bootstrap.css';

import {db} from '../../../firebase';
import {collection, addDoc, doc, deleteDoc} from 'firebase/firestore';

import Alert from 'react-bootstrap/Alert';
//import Button from 'react-bootstrap/Button';
import AuthContext from '../../../store/auth-context';

import { useCollection } from '../../../hooks/useCollection';
import Spinner from 'react-bootstrap/Spinner';
import Button from 'react-bootstrap/Button';
import Table from 'react-bootstrap/Table';
import Badge from 'react-bootstrap/Badge';
import {Link} from 'react-router-dom';



function LegalRain ({ btnText, onClick }) {

    const [showA, setShowA] = useState(false);
    const [docID, setdocID] = useState(null);
    const [newFecha, setNewFecha] = useState('')

    const authCtx = useContext(AuthContext);
    let user; let query;

    if (authCtx.userdetails){
        user=JSON.parse(authCtx.userdetails);
    }

    query =['active', '==',  true];
    const {documents: rains} = useCollection('legalRain',  query)  
    
    //console.log(rains);
   // alert(rains);

    /*if(rains!==null){
        rains.map(rain=>(console.log('lluvia', rain.fecha)))
    }*/

    //let rains=null;

    const handleSubmit = async (e) => {
        e.preventDefault()

        const ref=collection(db, 'legalRain')
    
       let result= await addDoc(ref, {
          active: true,
          createdDate:new Date(),
          fecha:newFecha,
          createdUserID: user.id
        })

        if(result.id){
            setShowA(true);
            setdocID(result.id);
        }
        setNewFecha('')
    }

    const handleDelete = async(id) => {
        if (window.confirm(`Esta seguro de borrar esta lluvia ${id}?`)) {
            const refDoc = doc(db, 'legalRain', id)
            await deleteDoc(refDoc)
            alert('lluvia borrada '+id);
        }
    }

    return(
        <div className='dashboard-content'>

        <div className='dashboard-content-container'>
            <div className='dashboard-content-header'>
                

                <div className="card card-body">
                    {showA && (
                    <Alert variant="success" onClose={() => setShowA(false)} dismissible>
                    <Alert.Heading>Lluvia Agregada! Feliciades!</Alert.Heading>
                        <p>
                       Lluvia legal con ID:<strong> {docID} </strong> agregada exitosamente
                        </p>
                    </Alert>
                    )
                    }

                <h2>Nueva Lluvia Legal</h2>
                <div className='dashboard-content-search'>
                   
                </div>

                             <div className="card-header bg-info" style={{marginBottom:'5px'}}>
                                <h4 className="mb-0 text-white"> </h4>
                            </div>
                            
                            <form className="form-horizontal" onSubmit={handleSubmit}>
								
                                
								<div className="form-floating mb-3">
									<input type="date" className="form-control border border-success" placeholder="2022-07-21" onChange={(e) => setNewFecha(e.target.value)} value={newFecha} required />
									<label><i data-feather="lock" className="feather-sm  text-success fill-white me-2"></i><span className="border-start border-success ps-3">Fecha</span></label>
								</div>
												
  
								<div className="form-actions">
                                        <div className="card-body border-top">
                                            <button type="submit" className="btn btn-success rounded-pill px-4"> 
                                                <div className="d-flex align-items-center">
                                                    <i data-feather="save" className="feather-sm me-1 fill-icon"></i> Guardar
                                                </div>
                                            </button>
                                           
                                        </div>
                                    </div>
                            </form>
                        </div>
            </div>
            {/*rains && (<>
                {rains.map(rain=>(<p key={rain.id}> {rain.id} </p>))}
            </>)*/}
            {rains!==null ? (
                            <Table striped bordered hover>
                                <thead>
                                    <tr>
                                        <th>ID</th>
                                        <th >fecha</th>
                                        <th>Creado</th>
                                        <th>Acciones</th>
                                    </tr>
                                </thead>
                                {rains && (
                                <tbody>
                                    {rains.map(r=>(
                                    <tr key={r.id}  >
                                        <td>{r.id}</td>
                                        <td>{r.fecha}</td>
                                        <td>{
                                        <>
                                        {/*r.createdDate.toDate().getFullYear()} -
                                        {r.createdDate.toDate().getMonth()+1} -
                                        {r.createdDate.toDate().getDate()} |&nbsp;
                                        {*/r.createdDate.toDate().toJSON()}
                                        </>
                                        }</td>
                                        <td> 
                                           
                                            {user.type==='2' &&(<>
                                           <Link to="/legalrain" onClick={ () => handleDelete(r.id)}> <Badge pill bg="danger">Borrar</Badge></Link>
                                            </>
                                            )}
                                        </td>
                                    </tr>
                                   )
                                 )}
                                </tbody>
                                )}
                            </Table>) : (
                     
                            <Button variant="primary" disabled>
                                <Spinner
                                as="span"
                                animation="border"
                                size="sm"
                                role="status"
                                aria-hidden="true"
                                /> 
                                 Loading...
                            </Button>

                            )}

        </div>
    </div>
    )
}

export default LegalRain;