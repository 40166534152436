import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';

import { useContext } from 'react';
import AuthContext from '../../../store/auth-context';

const TopMenu = ()=>{
    const authCtx = useContext(AuthContext);

    let user;

    if (authCtx.userdetails){
       user=JSON.parse(authCtx.userdetails);
    }else{
        user='';
    }

    const logoutHandler = () => {
        authCtx.logout();
        // optional: redirect the user
    };

    return(
        <Navbar collapseOnSelect expand="lg" bg="dark" variant="dark" sticky="top">
        <Container>
            <Navbar.Brand href="/">Construyen2</Navbar.Brand>
            <Navbar.Toggle aria-controls="responsive-navbar-nav" />
            <Navbar.Collapse id="responsive-navbar-nav">
            <Nav className="me-auto">
                {/*<Nav.Link href="#features">Vista General</Nav.Link>*/}

               {user.type==='2' && (
                <NavDropdown title="Villas/Solares" id="collasible-nav-dropdown">
                    <NavDropdown.Item href="/lots">Nuevo</NavDropdown.Item>
                    <NavDropdown.Item href="/viewlots">
                        Ver todos
                    </NavDropdown.Item>
                </NavDropdown>
                )}

                <NavDropdown title="Reportes diarios" id="collasible-nav-dropdown">
                    <NavDropdown.Item href="/activity">Nuevo reporte</NavDropdown.Item>
                    <NavDropdown.Item href="/activities">
                        Ver todos
                    </NavDropdown.Item>
                </NavDropdown>

                {user.type==='2' && (
                 <NavDropdown title="Inspectores" id="collasible-nav-dropdown">
                    <NavDropdown.Item href="/staff">Crear nuevo</NavDropdown.Item>
                    <NavDropdown.Item href="/viewstaff">
                        Ver todos
                    </NavDropdown.Item>
                 </NavDropdown>
                 )}

                {user.type==='2' && (
                 <NavDropdown title="Construccion" id="collasible-nav-dropdown">
                    <NavDropdown.Item href="#action/3.1">Responsabilidad RCL</NavDropdown.Item>
                    <NavDropdown.Item href="#action/3.2">
                       Actividades de Construccion
                    </NavDropdown.Item>
                    <NavDropdown.Item href="/builders">Nueva Constructoras</NavDropdown.Item>
                    <NavDropdown.Item href="/viewbuilders">Ver Constructoras</NavDropdown.Item>
                    <NavDropdown.Divider />
                    <NavDropdown.Item href="/legalrain">
                        Lluvia legal
                    </NavDropdown.Item>
                 </NavDropdown>
                )}

                {user.type==='2' && (
                <NavDropdown title="Reportes Semanales" id="collasible-nav-dropdown">
                    <NavDropdown.Item href="/wreport">Nuevo</NavDropdown.Item>
                    <NavDropdown.Item href="/wreports">
                        Ver todos
                    </NavDropdown.Item>
                </NavDropdown>
                )}

                <Nav.Link href="/login" onClick={logoutHandler}>Salir</Nav.Link>
            </Nav>
            {/*<Nav>
                <Nav.Link href="#deets">More deets</Nav.Link>
                <Nav.Link eventKey={2} href="#memes">
                Dank memes
                </Nav.Link>
            </Nav>*/}
            </Navbar.Collapse>
        </Container>
        </Navbar>
    )
}

export default TopMenu;
