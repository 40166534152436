import React, {useState, useCallback} from 'react';
import {useNavigate} from 'react-router-dom';
import DashboardHeader from '../../layout/DashboardHeader';

import '../styles.css';
import 'bootstrap/dist/css/bootstrap.css';

import {db} from '../../../firebase';
import {collection, addDoc} from 'firebase/firestore';

import Alert from 'react-bootstrap/Alert';
//import Button from 'react-bootstrap/Button';



function ReportForm ({ btnText, onClick }) {
    const navigate = useNavigate();
    const handleOnClick = useCallback(() => navigate('/viewstaff', {replace: true}), [navigate]);

    const [showA, setShowA] = useState(false);
    const [docID, setdocID] = useState(false);


    const [email, setEmail] = useState('')
    const [fname, setFname] = useState('')
    const [lname, setLname] = useState('')
    const [pswd, setPswd] = useState('')
    const [pho, setPho] = useState('')
    const [level, setLevel] = useState('')
    const [user, setUser] = useState('')
    

    const handleSubmit = async (e) => {
        e.preventDefault()
        //console.log(newBook)
        const ref=collection(db, 'staff')
    
       let result= await addDoc(ref, {
          active: true,
          createddate: new Date(),
          email:email,
          fname:fname,
          lname: lname,
          password:pswd,
          phone:pho,
          type:level,
          username:user
        })

        if(result.id){
            setShowA(true);
            setdocID(result.id);
        }

        console.log(result.id);
    
        setEmail('')
        setFname('')
        setLname('')
        setPswd('')
        setPho('')
        setLevel('')
        setUser('')
      }

    return(
        <div className='dashboard-content'>
        <DashboardHeader
            btnText="Ver Inspectores" onClick={handleOnClick}/>


        <div className='dashboard-content-container'>
            <div className='dashboard-content-header'>
                

                <div className="card card-body">
                    {showA && (
                    <Alert variant="success" onClose={() => setShowA(false)} dismissible>
                    <Alert.Heading>Staff Agregado! Feliciades!</Alert.Heading>
                        <p>
                            Staff con ID:<strong> {docID} </strong> agregado exitosamente
                        </p>
                    </Alert>
                    )
                    }

                <h2>Nuevo Staff</h2>
                <div className='dashboard-content-search'>
                   
                </div>

                             <div className="card-header bg-info" style={{marginBottom:'5px'}}>
                                <h4 className="mb-0 text-white">Formulario para nuevo inspector o usuario</h4>
                            </div>
                            
                            <form className="form-horizontal" onSubmit={handleSubmit}>
								<div className="mb-3">
                                    <label>Nombres</label>
                                    <input className="form-control" type="text" placeholder="Solo nombres" onChange={(e) => setFname(e.target.value)} value={fname} required/>
                                </div>
								<div className="mb-3">
                                    <label>Apellidos</label>
                                    <input className="form-control" type="text" placeholder="Solo apellidos" onChange={(e) => setLname(e.target.value)} value={lname} required />
                                </div>
                                <div className="mb-3">
                                    <label>Correo Electronico</label>
                                    <input className="form-control" type="email" placeholder="email" onChange={(e) => setEmail(e.target.value)} value={email} required />
                                </div>
                                <div className="mb-3">
                                    <label>Telefono</label>
                                    <input className="form-control" type="text" placeholder="Numero de telefono" onChange={(e) => setPho(e.target.value)} value={pho} required />
                                </div>
                                <div className="mb-3">
                                    <label>Usuario</label>
                                    <input className="form-control" type="text" placeholder="Nombre de usuario" onChange={(e) => setUser(e.target.value)} value={user} required />
                                </div>
                                <div className="mb-3">
                                    <label>Contraseña</label>
                                    <input className="form-control" type="password" placeholder="" onChange={(e) => setPswd(e.target.value)} value={pswd} required />
                                </div>
                                
								<div className="mb-3">
                                    <label>Nivel de acceso</label>
                                    <select selected={level} className="form-select col-12" id="inlineFormCustomSelect" onChange={(e) => setLevel(e.target.value)} required>
                                        <option >Elegir...</option>
                                        <option value="1">Inspector</option>
                                        <option value="2">Administrador</option>
                                </select>
                                </div>								
								<div className="form-actions">
                                        <div className="card-body border-top">
                                            <button type="submit" className="btn btn-success rounded-pill px-4"> 
                                                <div className="d-flex align-items-center">
                                                    <i data-feather="save" className="feather-sm me-1 fill-icon"></i> Guardar
                                                </div>
                                            </button>
                                            <button type="button" className="btn btn-danger rounded-pill px-4 ms-2 text-white">Cancelar</button>
                                        </div>
                                    </div>
                            </form>
                        </div>
            </div>
        </div>
    </div>
    )
}

export default ReportForm;