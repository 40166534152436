import React, {useContext} from 'react';
import { BrowserRouter as Router, Routes, Route  } from 'react-router-dom';

import SideBar from './components/layout/Sidebar';
import sidebar_menu from './components/constants/sidebar-menu';
import sidebar_menuInspectors from './components/constants/sidebar-menuInspectors';
//import Container from 'react-bootstrap/Container';
import NavigationMenu from "./components/layout/TopMenu";

import './App.css'
import ViewActivities from './components/pages/Activities/view'
import NewActivity from './components/pages/Activities'
import UpdateActivity from './components/pages/Activities/edit'
import HomePage from './components/pages/Home'
import StaffPage from './components/pages/Staff'
import VStaffPage from './components/pages/Staff/viewinspectors'
import UpdateStaff from './components/pages/Staff/edit'
import LotsPage from './components/pages/Lots'
import UpdateLot from './components/pages/Lots/edit'
import VLotsPage from './components/pages/Lots/viewlots'
import BuildersPage from './components/pages/Builders'
import VBuildersPage from './components/pages/Builders/viewbuilder'
import UpdateBuilder from './components/pages/Builders/edit'
import LoginPage from './components/pages/Login'
import LegalRainPage from './components/pages/LluviaLegal'

import WReportPage from './components/pages/weeklyreport'
import WReportViewPage from './components/pages/weeklyreport/view'
import WReportEditPage from './components/pages/weeklyreport/edit'

import AuthContext from './store/auth-context';
import { Navigate } from 'react-router-dom';



function App () {
  const authCtx = useContext(AuthContext);

  let user;

  if (authCtx.userdetails){
     user=JSON.parse(authCtx.userdetails);
  }else{
      user='';
  }

  return(
    <Router>
      {authCtx.isLoggedIn &&(
        <NavigationMenu/>
      )}
      
     
      <div className='dashboard-container'>
      {user.type==='2' && (
        authCtx.isLoggedIn &&(<SideBar  menu={sidebar_menu} />)
      )}
        {user.type==='1' && (
          authCtx.isLoggedIn &&(<SideBar  menu={sidebar_menuInspectors} />)
        )}

          <div className='dashboard-body'>
            
              <Routes>
              {!authCtx.isLoggedIn &&(
                  <Route exact path="/login" element={< LoginPage/>} />
              )}
                  {authCtx.isLoggedIn ? (
                    <>
                  <Route path="*" element={<div>home</div>} />
                  <Route exact path="/" element={<HomePage/>} />
                  <Route exact path="/activity" element={< NewActivity/>} />
                  <Route path='/activities/:id' element={<UpdateActivity/>} />
                  <Route exact path="/activities" element={< ViewActivities/>} />
                  <Route exact path="/lots" element={<LotsPage/>} />
                  <Route exact path="/viewlots" element={<VLotsPage/>} />
                  <Route exact path="/viewlots/:id" element={<UpdateLot/>} />
                  <Route exact path="/staff" element={<StaffPage/>} />
                  <Route exact path="/viewstaff" element={<VStaffPage/>} />
                  <Route exact path="/viewstaff/:id" element={<UpdateStaff/>} />
                  <Route exact path="/legalrain" element={<LegalRainPage/>} />
                  <Route exact path="/builders" element={<BuildersPage/>} />
                  <Route exact path="/viewbuilders" element={<VBuildersPage/>} />
                  <Route exact path="/viewbuilders/:id" element={<UpdateBuilder/>} />
                  <Route exact path="/profile" element={<div>profile</div>} />

                  <Route exact path="/wreport" element={<WReportPage/>} />
                  <Route exact path="/wreports" element={<WReportViewPage/>} />
                  <Route exact path="/wreports/:id" element={<WReportEditPage/>} />
                  </>
                  ): (
                   <Route path="*" element={<Navigate to="/login" />} />
                  ) }
              </Routes>
          
          </div>
      </div>
      
    </Router>
  )
}

export default App;