
import React, {useState, useEffect, useCallback, useContext} from 'react';
import {useNavigate, Link} from 'react-router-dom';

import DashboardHeader from '../../layout/DashboardHeader';

import '../styles.css';


import { useCollection } from '../../../hooks/useCollection';
import Table from 'react-bootstrap/Table';
import Badge from 'react-bootstrap/Badge';

import { db } from '../../../firebase'
import {getDoc, doc, deleteDoc, onSnapshot } from 'firebase/firestore'
import { async } from '@firebase/util';

import Spinner from 'react-bootstrap/Spinner';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Container from 'react-bootstrap/Container';
import GetSingleDoc from '../../../firebase/functions';

import AuthContext from '../../../store/auth-context';

const Reportes = ()=> {
    const [modalShow, setModalShow] =useState(false);
    const [singleReport, setSingleReport] =useState('');
   // const [inspector, setInspector]=useState(null);
   const [vi, setVi]=useState(null);
    let villa;

    const navigate = useNavigate();
    const handleOnClick = useCallback(() => navigate('/activity', {replace: true}), [navigate]);
    
    const authCtx = useContext(AuthContext);
    let user; let query;


    if (authCtx.userdetails){
      user=JSON.parse(authCtx.userdetails);
      //setNewInspectorid(user.id);
      if(user.type==='2' ){
        query =['active', '==',  true];
      }
      if(user.type==='1' ){
        query =['inspectorid', '==',  user.id];
      }
      //setNewInspectorid(user.id);
   }else{
       user='';
       query =['active', '==',  true];
   }

    const {documents: orders} = useCollection('activities', query)

   // console.log(orders);

    const handleDelete = async(id) => {
        if (window.confirm(`Esta seguro de borrar este elemento ${id}?`)) {
            const refDoc = doc(db, 'activities', id)
            await deleteDoc(refDoc)
            alert('Elemento Borrado '+id);
        }
    }

    const ChangeHandle = () => {
       // alert('change pressed')

    }
    const HandelModalItem=(props)=>{
        setModalShow(true)
        setSingleReport(props)
    }

    const MyVerticallyCenteredModal= (props) => {
       
        let nota;
       // let inspector;
       // let bool;
        //alert(props.item)

        if(props.item!==''){
            nota = JSON.parse(props.item);
            const docRef = doc(db, 'staff', nota.inspectorid)
       
            getDoc(docRef)
            .then((doc)=>{
                let results=[]
                //console.log(doc.data(), doc.id)
                results.push({...doc.data(), id:doc.id})
                localStorage.setItem(nota.inspectorid, JSON.stringify(results[0]));
            }) 
       }else{
            nota = '';
            //inspector='';
       }
       const insp=JSON.parse(localStorage.getItem(nota.inspectorid));

       let villa
       //nota.villadetails ? villa=JSON.parse(nota.villadetails) : villa=''
      //console.log('modal', villa);
      nota.villadetails ? villa=nota.villadetails : villa=''
       
        return (
        <Container>
          <Modal
            {...props}
            size="lg"
          >
            <Modal.Header closeButton>
              <Modal.Title >
                Detalles del reporte
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <h4>Anotacion No. {nota.number} </h4>
              <p>Fecha: {nota.fecha} |  {nota.inspdetails &&(<strong>{nota.inspdetails.fname} {nota.inspdetails.lname}</strong>)} | Villa: <strong>{villa.number}</strong></p>
              <p>
              {nota.comments}
              </p>
            </Modal.Body>
            <Modal.Footer>
              <Button onClick={props.onHide}>Close</Button>
            </Modal.Footer>
          </Modal>
        </Container>
        );
    }

    const HandleNoteType = (type)=>{
      let notatxt;
      switch(type){
        case '1': notatxt= 'Lluvia'; break;
        case '2': notatxt= 'Diaria'; break;
        case '3': notatxt= 'Vaciado'; break;
        case '4':notatxt= 'Multa'; break;
        case '5': notatxt= 'Atraso'; break;
        default: notatxt='unknown';
      }
      return notatxt;
    }
    
   /* const HandleSingleDocnpm=(coleccion, id)=>{
        const [documento, setDocumento]=useState(null)
    
    
        useEffect(()=>{
            let ref=doc(db, coleccion, id)
    
            const unsub = onSnapshot(ref, (doc)=>{
                let results=[]
                results.push({...doc.data(), id:doc.id})
                setDocumento(results)
            })
    
            return () => unsub()
        }, [coleccion, id])
        
        alert(documento);
        
        return {documento};
    }*/
  

    const ShowInspector = (id) => {

            const docRef = doc(db, 'staff', id)
                getDoc(docRef)
                .then((doc)=>{
                    let results=[]
                    results.push({...doc.data(), id:doc.id})
                    localStorage.setItem(id, JSON.stringify(results[0]));
                })    
            
            const insp=JSON.parse(localStorage.getItem(id));

            let details;
            if(insp){
                details=insp.fname+' '+insp.lname;
            }else{
                //window.location.reload();
            }
        return details;
    }

    const ShowVila = (id) => {

        const docRef = doc(db, 'villas-lots', id)
            getDoc(docRef)
            .then((doc)=>{
                let results=[]
                results.push({...doc.data(), id:doc.id})
                localStorage.setItem(id, JSON.stringify(results[0]));
            })    
        
        const insp=JSON.parse(localStorage.getItem(id));
        
        let details;
        if(insp){
            details=insp.number;
        }else{
            //window.location.reload();
        }
        
    return details;
}



    return(
        <div className='dashboard-content'>
            <MyVerticallyCenteredModal
                item={singleReport}
                show={modalShow}
                onHide={() => setModalShow(false)}
            />


            <DashboardHeader
                btnText="Crear nuevo reporte" onClick={handleOnClick}/>

            <div className='dashboard-content-container'>
            
              
                    <h2>Lista de anotaciones o reportes diarios</h2>
                   
                    {orders ? (
                            <Table striped bordered hover>
                                <thead>
                                    <tr>
                                    <th>No.</th>
                                    <th >fecha</th>
                                    <th>Inspector</th>
                                    <th>Enlace</th>
                                 
                                    <th>tipo</th>
                                    <th>villa</th>
                                    <th>Acciones</th>
                                    </tr>
                                </thead>
                                {orders!=null ? (
                                <tbody>
                                    {orders.map(order=>(
                                    <tr key={order.id} onClick={() => HandelModalItem(JSON.stringify(order))} >
                                    <td>{order.number}</td>
                                    <td>{order.fecha}</td>
                                    <td>{order.inspdetails.fname} {order.inspdetails.lname}<span></span></td>
                                    <td>{order.link}</td>
                                   
                                    <td>{ HandleNoteType(order.type)}</td>
                                    <td>{order.villadetails.number}
                                    {/*villa=JSON.parse(order.villadetails)*/}
                                    {/*villa.map(vi=><div key={vi.id}>{vi.number}</div>)*/}  
                                    </td>
                                    <td> <Link to={order.id} onClick={ChangeHandle}>  <Badge pill bg="primary">Editar</Badge></Link> 
                                    {user.type==='2' &&(<>
                                    | <Link to="/activities" onClick={ () => handleDelete(order.id)}> <Badge pill bg="danger">Borrar</Badge></Link>
                                    </>
                                    )}
                                    
                                    
                                    
                                    </td>
                                    </tr>
                                   )
                                 )}
                                </tbody>
                                )
                                : (
                                    <tbody><tr><td colSpan={4}>No Data</td></tr></tbody>
                                )
                                }
                            </Table>) : (
                            <>
                            <Button variant="primary" disabled>
                                <Spinner
                                as="span"
                                animation="border"
                                size="sm"
                                role="status"
                                aria-hidden="true"
                                /> 
                                 Loading...
                            </Button>
                             {/*<Spinner animation="border" variant="danger" />*/}
                             </>
                            )}

            </div>
        </div>
    )
}



export default Reportes;