import { getDoc, doc } from "firebase/firestore"
import { db } from "."
import { useState, useEffect} from "react";

const GetSingleDoc = (collectionName, id)=>{
    const [item, setItem]=useState(null);
    //alert('single doc')
    //get a single document
    const docRef = doc(db, collectionName, id)

    useEffect(()=>{
        getDoc(docRef)
        .then((doc)=>{
            let results=[]
            //console.log(doc.data(), doc.id)
            results.push({...doc.data(), id:doc.id})
           
            setItem(JSON.stringify(results[0]))

           // console.log(item)
        })
    },[])
  

    return item
}

export default GetSingleDoc