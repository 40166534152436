
import React, {useState, useEffect, useCallback, useContext} from 'react';
import {useNavigate, Link} from 'react-router-dom';

import DashboardHeader from '../../layout/DashboardHeader';

import '../styles.css';


import { useCollection } from '../../../hooks/useCollection';
import Table from 'react-bootstrap/Table';
import Badge from 'react-bootstrap/Badge';

import { db } from '../../../firebase'
import {getDoc, doc, deleteDoc, onSnapshot } from 'firebase/firestore'
import { async } from '@firebase/util';

import Spinner from 'react-bootstrap/Spinner';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Container from 'react-bootstrap/Container';
import GetSingleDoc from '../../../firebase/functions';

import AuthContext from '../../../store/auth-context';





const ViewWeeklyReports = () =>{

    const [modalShow, setModalShow] =useState(false);
    const [singleReport, setSingleReport] =useState('');

    const navigate = useNavigate();
    const handleOnClick = useCallback(() => navigate('/wreport', {replace: true}), [navigate]);
    
    const authCtx = useContext(AuthContext);
    let user; let query;


    if (authCtx.userdetails){
      user=JSON.parse(authCtx.userdetails);
      //setNewInspectorid(user.id);
      if(user.type==='2' ){
       // query =['active', '==',  true];
        
      }
      if(user.type==='1' ){
       // query =['inspectorid', '==',  user.id];
      }
      //setNewInspectorid(user.id);
   }else{
       user='';
      // query =['active', '==',  true];
   }

   const {documents: weeklyreports} = useCollection('weeklyreport', query)
  

    const HandelModalItem=(props)=>{
        setModalShow(true)
        setSingleReport(props)
    }
    
    const MyVerticallyCenteredModal= (props) => {
           
        let nota;
       // let inspector;
       // let bool;
        //alert(props.item)
    
        if(props.item!==''){
            nota = props.item;
            //const docRef = doc(db, 'staff', nota.inspectorid)
       
            /*getDoc(docRef)
            .then((doc)=>{
                let results=[]
                //console.log(doc.data(), doc.id)
                results.push({...doc.data(), id:doc.id})
                localStorage.setItem(nota.inspectorid, JSON.stringify(results[0]));
            }) */
       }else{
            nota = '';
            //inspector='';
       }
       //const insp=JSON.parse(localStorage.getItem(nota.inspectorid));
    
       let villa
       //nota.villadetails ? villa=JSON.parse(nota.villadetails) : villa=''
      //console.log('modal', villa);
      nota.villadetails ? villa=nota.villadetails : villa=''
       
        return (
        <Container>
          <Modal
            {...props}
            size="lg"
          >
            <Modal.Header closeButton>
              <Modal.Title >
                Detalles de reporte semanal
              </Modal.Title>
            </Modal.Header>
            {nota && (
            <Modal.Body>
              <h4>Villa: {nota.villadetails.number} Fecha: {convertDate(nota.date)}</h4>
              <p>Comentario: <strong> {nota.text1}</strong></p>
              <p>Comentario 2: <strong> {nota.text2}</strong></p>
              <p>Estado Actual: <strong> {nota.text3}</strong></p>
              <p>
              {nota.comments}
              </p>
            </Modal.Body>
            )}
            <Modal.Footer>
              <Button onClick={props.onHide}>Close</Button>
            </Modal.Footer>
          </Modal>
        </Container>
        );
    }

    //const orders=null

    const HandleNoteType=()=>{

    }
    const handleDelete= async(id) => {
      if (window.confirm(`Esta seguro de borrar este reporte semanal ${id}?`)) {
        const refDoc = doc(db, 'weeklyreport', id)
        await deleteDoc(refDoc)
        alert('Reporte Semanal Borrado '+id);
      }
    }

    const convertDate=(date)=>{
      //let result = new Intl.DateTimeFormat('en-US', {year: 'numeric', month: '2-digit',day: '2-digit', hour: '2-digit', minute: '2-digit', second: '2-digit'}).format(date)
      //date.toString()
      //var yyyy = date.getFullYear();
      //var mm = date.getMonth() + 1; // getMonth() is zero-based
      //var dd = date.getDate();
      let result=new Date(date.toDate()).toDateString()

      return result
    }

    return(
        <div className='dashboard-content'>
        <MyVerticallyCenteredModal
            item={singleReport}
            show={modalShow}
            onHide={() => setModalShow(false)}
        />


        <DashboardHeader
            btnText="Crear nuevo reporte" onClick={handleOnClick}/>

        <div className='dashboard-content-container'>
        
          
                <h2>Lista reportes semanales</h2>
               
                {weeklyreports ? (
                        <Table striped bordered hover>
                            <thead>
                                <tr>
                                <th>ID</th>
                                <th>fecha</th>
                                
                                <th>villa</th>
                                <th>Acciones</th>
                                </tr>
                            </thead>
                            {weeklyreports!=null ? (
                            <tbody>
                                {weeklyreports.map(wr=>(
                                <tr key={wr.id} onClick={() => HandelModalItem(wr)}>
                                <td   >{wr.id}</td>
                                <td>{convertDate(wr.date)}</td>
                                
                                <td>{wr.villadetails.number}
                               
                                </td>
                                <td> <Link to={wr.id} >  <Badge pill bg="primary">Editar</Badge></Link> 
                                {user.type==='2' &&(<>
                                | <Link to="/wreports" onClick={ () => handleDelete(wr.id)}> <Badge pill bg="danger">Borrar</Badge></Link>
                                </>
                                )}
                                
                                
                                
                                </td>
                                </tr>
                               )
                             )}
                            </tbody>
                            )
                            : (
                                <tbody><tr><td colSpan={4}>No Data</td></tr></tbody>
                            )
                            }
                        </Table>) : (
                        <>
                        <Button variant="primary" disabled>
                            <Spinner
                            as="span"
                            animation="border"
                            size="sm"
                            role="status"
                            aria-hidden="true"
                            /> 
                             Loading...
                        </Button>
                         {/*<Spinner animation="border" variant="danger" />*/}
                         </>
                        )}

        </div>
    </div>
    )
       
}

export default ViewWeeklyReports