import React, {useState, useCallback} from 'react';
import {useNavigate} from 'react-router-dom';
import DashboardHeader from '../../layout/DashboardHeader';

import '../styles.css';
import 'bootstrap/dist/css/bootstrap.css';

import {db} from '../../../firebase';
import {collection, addDoc} from 'firebase/firestore';

import { useCollection } from '../../../hooks/useCollection';

import Alert from 'react-bootstrap/Alert';
//import Button from 'react-bootstrap/Button';



const CreateVilla = () => {
    const navigate = useNavigate();
    const handleOnClick = useCallback(() => navigate('/viewlots', {replace: true}), [navigate]);

    const [showA, setShowA] = useState(false);
    const [docID, setdocID] = useState(false);


    const [VillaNumber, setVillaNumber] = useState('')
    const [VillaM2, setVillaM2] = useState('')
    const [VillaPhase, setVillaPhase] = useState('')
    const [VillaBuilder, setVillaBuilder] = useState('')
    const [VillaInspector, setVillaInspector] = useState('')
   // const [VillaBuilderName, setVillaBuilderName] = useState('')
    //const [VillaInspectorName, setVillaInspectorName] = useState('')
    const [FechaInicio, setFechaInicio] = useState('')
    const [FechaInicioR, setFechaInicioR] = useState('')
    const [FechaTermino, setFechaTermino] = useState('')
    const [FechaTerminoR, setFechaTerminoR] = useState('')
    const [VillaStatus, setVillaStatus] = useState('')

    const {documents: builders} = useCollection(
        'builder',
        ['active', '==', true]
    )

    const {documents: inspectors} = useCollection(
        'staff',
        ['type', '==', '1']
    )
    //console.log('builders:', builders);
    //console.log('inespectors:', inspectors);
    
    //alert('top 50');

    const handleSubmit = async (e) => {
        e.preventDefault()
        //console.log(newBook)
        const ref=collection(db, 'villas-lots')
    
       let result= await addDoc(ref, {
          active: true,
          builderid: VillaBuilder,
          createddate:new Date(),
          createduserid:'',
          enddate: FechaTermino,
          inspectorid:VillaInspector,
          m2:VillaM2,
          number:VillaNumber,
          phase:VillaPhase,
          realend:FechaTerminoR,
          realstart:FechaInicioR,
          startdate:FechaInicio,
          status:VillaStatus
        })

        if(result.id){
            setShowA(true);
            setdocID(result.id);
        }

        //console.log(result.id);
    
        setVillaNumber('')
        setVillaM2('')
        setVillaPhase('')
        setVillaBuilder('')
        setVillaInspector('')
        setFechaInicio('')
        setFechaInicioR('')
        setFechaTermino('')
        setFechaTerminoR('')
        setVillaStatus('')
    }

    return(
        <div className='dashboard-content'>
        <DashboardHeader
            btnText="Ver villas" onClick={handleOnClick}/>


        <div className='dashboard-content-container'>
            <div className='dashboard-content-header'>
                

                <div className="card card-body">
                    {showA && (
                    <Alert variant="success" onClose={() => setShowA(false)} dismissible>
                    <Alert.Heading>Lote Agregado! Feliciades!</Alert.Heading>
                        <p>
                        Solar con ID:<strong> {docID} </strong> agregado exitosamente
                        </p>
                    </Alert>
                    )
                    }

                <h2>Nueva Villa</h2>
                <div className='dashboard-content-search'>
                   
                </div>

                             <div className="card-header bg-info" style={{marginBottom:'5px'}}>
                                <h4 className="mb-0 text-white">Crear una nuevo lote/Solar (villa)</h4>
                            </div>
                            
                            <form className="form-horizontal" onSubmit={handleSubmit}>
								<div className="mb-3">
                                    <label>Numero</label>
                                    <input className="form-control" type="text" placeholder="Numero de Solar" onChange={(e) => setVillaNumber(e.target.value)} value={VillaNumber} required/>
                                </div>
								<div className="mb-3">
                                    <label>M2 del solar</label>
                                    <input className="form-control" type="text" placeholder="Metros cuadrados" onChange={(e) => setVillaM2(e.target.value)} value={VillaM2} required />
                                </div>
                                <div className="mb-3">
                                    <label>Fase</label>
                                    <input className="form-control" type="text" placeholder="Fase" onChange={(e) => setVillaPhase(e.target.value)} value={VillaPhase} required />
                                </div>
								 <div className="mb-3">
                                    <label>Constructor</label>
                                    <select selected={VillaBuilder} className="form-select col-12" id="inlineFormCustomSelect" onChange={(e) => setVillaBuilder(e.target.value)} required>
                                        <option >Elegir...</option>
                                        {builders!=null ? (
                                            builders.map(build=>(<option key={build.id} value={build.id}>{build.name}</option>))
                                        ):''}
                                    </select>
                                </div>
                                <div className="mb-3">
                                    <label>Inspector</label>
                                    <select selected={VillaInspector} className="form-select col-12" id="inlineFormCustomSelect" onChange={(e) => setVillaInspector(e.target.value)} required>
                                        <option >Elegir...</option>
                                        {inspectors!=null ? (
                                            inspectors.map(insp=>(<option key={insp.id} value={insp.id}>{insp.fname}</option>))
                                        ):''}
                                    </select>
                                </div>
                                
								<div className="form-floating mb-3">
									<input type="date" className="form-control border border-success" placeholder="2022-07-21" onChange={(e) => setFechaInicio(e.target.value)} value={FechaInicio} required />
									<label><i data-feather="lock" className="feather-sm  text-success fill-white me-2"></i><span className="border-start border-success ps-3">Fecha de Inicio</span></label>
								</div>
                                <div className="form-floating mb-3">
									<input type="date" className="form-control border border-success" placeholder="2022-07-21" onChange={(e) => setFechaInicioR(e.target.value)} value={FechaInicioR} required />
									<label><i data-feather="lock" className="feather-sm  text-success fill-white me-2"></i><span className="border-start border-success ps-3">Fecha Real de Inicio</span></label>
								</div>
                                <div className="form-floating mb-3">
									<input type="date" className="form-control border border-success" placeholder="2022-07-21" onChange={(e) => setFechaTermino(e.target.value)} value={FechaTermino} required />
									<label><i data-feather="lock" className="feather-sm  text-success fill-white me-2"></i><span className="border-start border-success ps-3">Fecha de Terminada</span></label>
								</div>
                                <div className="form-floating mb-3">
									<input type="date" className="form-control border border-success" placeholder="2022-07-21" onChange={(e) => setFechaTerminoR(e.target.value)} value={FechaTerminoR} required />
									<label><i data-feather="lock" className="feather-sm  text-success fill-white me-2"></i><span className="border-start border-success ps-3">Fecha Real de Terminada</span></label>
								</div>
								<div className="mb-3">
                                    <label>Estado</label>
                                    <select selected={VillaStatus} className="form-select col-12" id="inlineFormCustomSelect" onChange={(e) => setVillaStatus(e.target.value)} required>
                                        <option >Elegir...</option>
                                        <option value="1">No Iniciada</option>
                                        <option value="2">En progreso</option>
                                        <option value="3">Terminada</option>
                                    </select>
                                </div>								
                               
                               
								<div className="form-actions">
                                        <div className="card-body border-top">
                                            <button type="submit" className="btn btn-success rounded-pill px-4"> 
                                                <div className="d-flex align-items-center">
                                                    <i data-feather="save" className="feather-sm me-1 fill-icon"></i> Guardar
                                                </div>
                                            </button>
                                            <button type="button" className="btn btn-danger rounded-pill px-4 ms-2 text-white">Cancelar</button>
                                        </div>
                                    </div>
                            </form>
                        </div>
            </div>
        </div>
    </div>
    )
}

export default CreateVilla;